<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" color="info" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        class="form-info"
      >
        <span v-if="isUpdate" class="">{{ $('update2') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form

          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.locked_cause"
                  v-max-length="200"
                  :label="$('locked_cause')"
                  :rules="rules.locked_cause"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>

        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'UserDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    roles: [],
    number: null,
    form: {
      locked_cause: '',

    },
  }),
  computed: {

    isUpdate() {
      return this.data.type === 'update'
    },

    rules() {
      const rules = {};
      rules.locked_cause = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.locked_cause`)]),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },

  methods: {
    async submit() {
      try {
        this.submitLoading = true;

        if (this.$refs.form.validate() === false) {
          return;
        }
        const formData = new FormData();
        formData.append('locked', 1);
        formData.append('locked_cause', this.form.locked_cause);
        if (this.isUpdate) {
          await axios.post(`/phoneNumber/${this.data.id}`, formData);
          await toast.dispatch('success', this.$t('form.success.update'))
        }

        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        locked: null,
        locked_cause: null,
      };
    },

    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
